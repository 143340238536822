<template>
  <el-dialog
    id="add-to-cart-dialog"
    :visible="isVisible"
    :modal="false"
    @close="$emit('close')"
    width="360px"
  >
    <el-container class="dialog-body">
      <span>{{ $t('message.elemento_aggiunto_al_carrello') | capitalize }}</span>
    </el-container>
    <span slot="footer" class="add-to-cart-actions">
      <el-button
        type="primary"
        @click="$emit('close')"
      >{{ $t('message.Continua') | upperCase }}</el-button>

      <el-button
        type="primary"
        @click="
          $emit('close');
          openCart();
        "
      >{{ $t('message.Vai_al_carrello') | upperCase }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { EventBus } from "../../plugins/EventBus";

  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      openCart() {
        EventBus.$emit("open-cart");
      },
    },
  };
</script>

<style lang="less" >
  #add-to-cart-dialog {
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-body {
      display: flex;
      justify-content: center;
      color: @--color-primary;
    }

    .add-to-cart-actions {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
</style>