/* eslint-disable no-unused-vars */
import _ from "lodash";
import * as THREE from "three";
import * as Metal from "./metal_materials.js";
// import * as Plastic from '../../plastic_materials.js'
import ajax_request from "../../plugins/ajax_request.js";
import { EventBus } from '../../plugins/EventBus'
// import { mapMutations } from "vuex";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper.js";
import QRCode from "qrcode"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

export default {
  // ...mapMutations(["setIdForUrl"]),

  init() {
    const container = document.getElementById("canvas-container");

    this.width = container.offsetWidth;
    this.height = container.offsetHeight;

    this.camera = new THREE.PerspectiveCamera(
      45,
      this.width / this.height,
      10,
      500
    );
    this.camera.position.z = 450;
    this.camera.position.y = 200;
    this.camera.position.x = 200;
    this.camera.updateProjectionMatrix();

    const cubeRenderTarget2 = new THREE.WebGLCubeRenderTarget(128, {
      format: THREE.RGBFormat,
      generateMipmaps: true,
      minFilter: THREE.LinearMipmapLinearFilter,
      encoding: THREE.sRGBEncoding, // temporary -- to prevent the material's shader from recompiling every frame
    });

    this.cubeCamera2 = new THREE.CubeCamera(1, 1000, cubeRenderTarget2);
    this.cubeCamera2.renderTarget.mapping = THREE.CubeRefractionMapping;
    this.scene.add(this.cubeCamera2);
    this.cubeCamera2.position.set(200, 60, 150);

    const cubeRenderTarget1 = new THREE.WebGLCubeRenderTarget(128, {
      format: THREE.RGBFormat,
      generateMipmaps: true,
      minFilter: THREE.LinearMipmapLinearFilter,
      encoding: THREE.sRGBEncoding, // temporary -- to prevent the material's shader from recompiling every frame
    });

    this.cubeCamera1 = new THREE.CubeCamera(1, 1000, cubeRenderTarget1);
    this.scene.add(this.cubeCamera1);
    this.cubeCamera1.position.set(30, 2, 25);

    this.cubeCamera1.update(this.render, this.scene);
    this.cubeCamera2.update(this.render, this.scene);

    // Inizio gestione luci

    const ambient = new THREE.AmbientLight(0xffffff, 0.3);
    this.scene.add(ambient);

    const spotLight = new THREE.SpotLight(0xffffff, 0.4);
    spotLight.position.set(-100, 200, 200);
    // spotLight.target.position.set(30, 0, 30);
    // spotLight.target.updateMatrixWorld();
    spotLight.angle = Math.PI / 4;
    spotLight.penumbra = 1;
    // spotLight.decay = 2;
    spotLight.distance = 800;
    spotLight.name = "ombra";
    spotLight.castShadow = true;
    // spotLight.shadow.mapSize.width = 1024;
    // spotLight.shadow.mapSize.height = 1024;
    spotLight.shadow.camera.near = 10;
    spotLight.shadow.camera.far = 500;
    // spotLight.shadow.focus = 1;
    // spotLight.shadow.bias = 0.00009;
    spotLight.shadow.needsUpdate = true;
    this.scene.add(spotLight);

    // const sh1 = new THREE.SpotLightHelper(spotLight, 0xff0000);
    // this.scene.add(sh1);

    // const spotLight1 = new THREE.SpotLight(0xffffff, 0.1);
    const spotLight1 = new THREE.SpotLight(0xffffff, 0.1);
    spotLight1.position.set(30, -10, 250);
    spotLight1.angle = Math.PI / 2;
    spotLight1.penumbra = 1;
    spotLight1.decay = 2;
    spotLight1.distance = 400;
    spotLight1.castShadow = false;
    spotLight1.name = "frontRightBottom";
    spotLight1.target.position.set(60, 12, 60);
    spotLight1.target.updateMatrixWorld();
    // this.scene.add(spotLight1);

    // const sh2 = new THREE.SpotLightHelper(spotLight1, 0x00ff00);
    // this.scene.add(sh2);

    const spotLight5 = new THREE.SpotLight(0xffffff, 0.2);
    spotLight5.position.set(60, 180, 250);
    spotLight5.angle = Math.PI / 8;
    spotLight5.penumbra = 1;
    spotLight5.decay = 1;
    spotLight5.distance = 350;
    spotLight5.castShadow = false;
    spotLight5.name = "frontRightTop";
    spotLight5.target.position.set(0, 12, -30);
    spotLight5.target.updateMatrixWorld();
    // this.scene.add(spotLight5);

    // const sh5 = new THREE.SpotLightHelper(spotLight5, 0x0000ff);
    // this.scene.add(sh5);

    const spotLight2 = new THREE.SpotLight(0xffffff, 0.4);
    spotLight2.position.set(200, 80, -100);
    spotLight2.angle = Math.PI / 2;
    spotLight2.penumbra = 1;
    spotLight2.decay = 2;
    spotLight2.distance = 400;
    spotLight2.name = "backRight";
    spotLight2.target.position.set(0, 0, 30);
    spotLight2.target.updateMatrixWorld();
    spotLight2.castShadow = false;
    // this.scene.add(spotLight2);

    // const sh3 = new THREE.SpotLightHelper(spotLight2, 0x0f0f0f);
    // this.scene.add(sh3);

    const spotLight3 = new THREE.SpotLight(0xffffff, 0.4);
    spotLight3.position.set(400, 20, -150);
    spotLight3.angle = Math.PI / 10;
    spotLight3.penumbra = 1;
    spotLight3.decay = 1;
    spotLight3.distance = 400;
    spotLight3.name = "asta-left";
    spotLight3.target.position.set(0, 10, -80);
    spotLight3.target.updateMatrixWorld();
    spotLight3.castShadow = false;
    this.scene.add(spotLight3);

    // const sh3 = new THREE.SpotLightHelper(spotLight3);
    // this.scene.add(sh3);

    const spotLight4 = new THREE.SpotLight(0xffffff, 0.4);
    spotLight4.position.set(-400, 20, -150);
    spotLight4.angle = Math.PI / 10;
    spotLight4.penumbra = 1;
    spotLight4.decay = 1;
    spotLight4.distance = 400;
    spotLight4.name = "asta-right";
    spotLight4.target.position.set(0, 10, -80);
    spotLight4.target.updateMatrixWorld();
    spotLight4.castShadow = false;
    this.scene.add(spotLight4);

    // const sh4 = new THREE.SpotLightHelper(spotLight4);
    // this.scene.add(sh4);

    // Fine gestione luci

    // this.render.setClearColor(0xffffff); //0x000000
    this.render.setClearColor(0xf4f4f4); //0x000000
    // this.render.setPixelRatio(window.devicePixelRatio);
    this.render.setSize(this.width, this.height);
    this.render.render(this.scene, this.camera);
    this.render.gammaInput = true;
    this.render.gammaOutput = true;
    this.render.shadowMap.enabled = true;
    this.render.shadowMap.type = THREE.PCFSoftShadowMap;
    this.render.physicallyBasedShading = true;
    this.render.tonemapping = THREE.LinearToneMapping;
    this.render.shadowMap.autoUpdate = true;
    this.render.shadowMap.needsUpdate = true;
    this.render.colorManagement = true;
    this.render.outputEncoding = THREE.sRGBEncoding;
    this.render.antialias = true;

    // var p_geometry = new THREE.PlaneBufferGeometry(2000, 2000, 5, 5);
    var p_geometry = new THREE.PlaneGeometry(2000, 2000, 5, 5);
    var p_material = new THREE.MeshStandardMaterial({
      color: 0xf4f4f4,
      // emissive: 0xc0c0c,
      emissive: new THREE.Color("rgb(244, 244, 244)"),
      emissiveIntensity: 0.5,
      transparent: true,
      opacity: 0.5,
      side: THREE.DoubleSide,
    });
    p_material.needsUpdate = true;
    this.plane = new THREE.Mesh(p_geometry, p_material);
    // this.plane.far_plane = 100;
    // this.plane.near_plane = 1;

    this.plane.receiveShadow = true;
    // this.plane.castShadow = true;
    this.plane.rotation.x = -1.57;
    this.plane.needsUpdate = true;

    // let fog = new THREE.Fog(new THREE.Color('#444444'), 200, 250);
    // this.scene.fog = fog;

    this.scene.add(this.plane);
    // this.doRender();

    container.appendChild(this.render.domElement);

    // window.addEventListener("resize", this.onContainerResize, false);
    EventBus.$on('page-resize', this.onContainerResize)
    const observer = new ResizeObserver(e => {
      this.onContainerResize()
    })

    EventBus.$on('reset-configuration', () => {
      this.temples_material.forEach(m => {
        console.log('pre', m);
        m.dispose();
        m.map = null;
        m.transparent = false
        console.log('post', m);
      })
      this.front_material.forEach(m => {
        m.dispose();
        m.map = null;
      })

      this.doRender();

    })

    observer.observe(container);
    // container.addEventListener("resize", this.onContainerResize, false);

    this.controls = new THREE.OrbitControls(this.camera, container);
    this.controls.enablePan = false;
    this.controls.enableZoom = true;
    // this.controls.enableDamping = true;
    // this.controls.dampingFactor = 0.08;
    this.controls.maxDistance = 350;
    this.controls.minDistance = 180;
    this.controls.keys = {};
    this.controls.maxAzimuthAngle = Math.PI / 2;
    this.controls.minAzimuthAngle = (Math.PI / 2) * -1;
    this.controls.maxPolarAngle = 1.5;
    this.controls.minPolarAngle = Math.PI / 3;
    this.controls.update();
    this.animate();
    // this.doRender();
    // this.controls.addEventListener("change", () => this.doRender());
  },
  onContainerResize() {
    let w = 0;
    let h = 0;


    let container = document.getElementById("canvas-container");

    w = container.offsetWidth;
    h = container.offsetHeight;

    this.camera.aspect = w / h;

    this.camera.updateProjectionMatrix();
    this.render.setSize(w, h);
    this.animate();


  },
  animate() {
    requestAnimationFrame(this.animate);

    this.controls.update();

    if (this.setCanvasPos) {
      this.camera.position.lerp(new THREE.Vector3(135, 104, 304), 0.1);
    }
    if (
      this.camera.position.x >= 134 &&
      this.camera.position.y >= 104 &&
      this.camera.position.z >= 303 &&
      this.setCanvasPos
    ) {
      this.$emit("settedPosition");
    }

    if (this.$store.state.animateToFront) {
      this.camera.position.lerp(new THREE.Vector3(3, 29, 347), 0.1);
    }
    if (
      this.camera.position.x >= 2 &&
      this.camera.position.y >= 28 &&
      this.camera.position.z >= 346 &&
      this.$store.state.animateToFront
    ) {
      this.$emit("settedFront");
    }
    if (this.$store.state.animateToTemple) {
      this.camera.position.lerp(new THREE.Vector3(335, 86, 11), 0.1);
    }
    if (
      this.camera.position.x >= 334 &&
      this.camera.position.y >= 85 &&
      this.camera.position.z >= 10 &&
      this.$store.state.animateToTemple
    ) {
      this.$emit("settedTemple");
    }

    this.render.render(this.scene, this.camera);
  },
  doRender() {
    this.render.render(this.scene, this.camera);
  },
  printSetup() {
    /**
     * preparo la canvas per lo screenshot
     * la espando e riposiziono la camera
     */

    return new Promise((resolve) => {
      this.$emit("closeMenu");

      this.camera.position.z = 150;
      this.camera.position.y = 55;
      this.camera.position.x = 85;

      // this.doRender();

      setTimeout(() => {
        let self = this;
        let json = JSON.stringify(this.getActiveConfiguration);
        ajax_request.setCustomConfigurations(json).then(function (res) {
          self.id_for_url = res.data;
          self.makeQRCode(res.data).then((url) => {
            self.qrcode = url;

            resolve();
            self.controls.update();
            self.doRender();
          });
        });
      }, 200);
    });
  },
  printFocus() {
    /**
     * attendo che il template venga montato con i nuovi dati ed eseguo il focus
     * dopodichè con emit resetto dal parent il variabile print a false
     */

    this.printSetup()
      .then(() => {
        return new Promise((resolve) => {
          let preview = "";
          let name = "";
          let finituraF =
            this.finitura == "S"
              ? this.$t("message.lucido")
              : this.$t("message.opaco");
          let finituraA =
            this.finituraAste == "S"
              ? this.$t("message.lucido")
              : this.$t("message.opaco");
          let metal =
            this.coloreMetalloAste == null
              ? "---"
              : this.coloreMetalloAste == "PRO0000272"
                ? "GUN"
                : "GOLD";
          let url_to_stamp = window.location.href;

          /**
           * controllo se il colore è sotto la categoria multistrato
           * aggiungo un'immagine per tutte le altre cateogrie
           * altrimenti aggiungo una tabella con l'infonografica
           */
          if (this.getActiveConfiguration.colore.Category != "MULTISTRATO") {
            var canvas = document.getElementsByTagName("canvas")[0];

            var img = canvas.toDataURL("image/png");

            preview = ['<img src="', img, '">'].join("");
          } else {
            preview = [
              '<br><br><br><table class="instructions">',
              "<h3>" + this.$t("message.Anteprima_non_disponibile") + "</h3>",
              "<tr><th>" +
              this.$t("message.Colore_esterno") +
              "</th><td></td></tr>",
              "<tr>",
              '<td><img src="https://media.thema-optical.com/colori_acetato/frontali/',
              this.frontColor,
              '.jpg" ></td>',
              '<td style="width: 60%">',
              '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 9310 2680" preserveAspectRatio="xMidYMid meet">',
              '<g id="layer101" fill="#010101" stroke="none">',
              '<path d="M725 2273 c-60 -15 -124 -84 -125 -133 0 -61 22 -79 425 -342 88 -57 242 -170 343 -251 101 -81 210 -166 244 -188 148 -101 302 -146 638 -189 571 -72 802 -102 920 -120 215 -31 1052 -114 1475 -146 196 -14 418 -35 470 -44 33 -6 139 -19 235 -30 212 -25 205 -24 222 -37 7 -6 54 -21 103 -33 50 -12 119 -31 153 -42 41 -13 67 -17 73 -11 12 12 -3 111 -32 225 -16 64 -20 124 -24 350 -3 150 -7 327 -10 393 -2 66 -5 211 -5 323 l0 202 -37 0 c-64 0 -109 -17 -123 -48 -9 -18 -16 -94 -20 -237 -7 -199 -9 -215 -39 -310 -44 -136 -50 -218 -22 -272 11 -23 25 -44 31 -48 14 -9 3 -48 -37 -132 -20 -41 -41 -73 -50 -73 -8 0 -12 4 -9 8 3 5 6 107 7 228 3 369 16 458 74 520 20 20 34 38 33 39 -2 1 -18 7 -36 13 -27 9 -40 9 -62 -3 -82 -43 -96 -107 -115 -530 -10 -230 -15 -265 -36 -265 -102 0 -633 66 -1289 160 -339 48 -508 57 -1280 62 -293 2 -521 10 -485 16 5 1 -65 77 -155 170 -91 92 -208 211 -260 266 -104 107 -154 140 -197 130 -47 -11 -122 -53 -133 -74 -21 -40 8 -67 285 -265 99 -71 200 -144 225 -164 25 -19 47 -37 49 -38 29 -20 -157 13 -255 45 -137 45 -239 114 -455 310 -85 77 -239 225 -343 331 -225 227 -274 258 -371 234z"/></g>',
              '<g id="layer102" fill="#58aa5d" stroke="none">',
              '<path d="M7971 1925 c-18 -9 -63 -36 -98 -61 -140 -100 -458 -241 -638 -282 -145 -34 -237 -50 -287 -51 -51 -1 -66 12 -72 67 -7 50 -27 112 -36 112 -5 0 -38 -24 -72 -53 -193 -162 -266 -213 -352 -248 -22 -9 -42 -21 -43 -26 -6 -18 54 -94 101 -128 27 -19 80 -47 120 -64 82 -34 343 -104 357 -95 14 8 11 71 -6 111 -8 19 -15 39 -15 44 0 11 67 29 109 29 31 0 86 13 226 52 286 79 529 226 687 416 56 67 128 172 128 187 0 11 -77 3 -109 -10z m-121 -183 c0 -25 -159 -152 -277 -222 -189 -110 -471 -182 -646 -164 l-67 7 0 -30 c0 -17 7 -50 15 -73 8 -23 15 -51 15 -62 0 -18 -5 -19 -37 -14 -101 18 -356 123 -361 149 -5 26 279 249 312 245 13 -2 24 -19 37 -57 25 -77 29 -79 166 -60 205 27 357 76 639 203 197 88 204 91 204 78z"/>',
              "</g></svg>",
              "</td>",
              "</tr>",
              "<tr><th>" +
              this.$t("message.Colore_interno") +
              "</th><td></td></tr>",
              "<tr>",
              '<td><img src="https://media.thema-optical.com/colori_acetato/frontali/',
              this.frontColor,
              '_b.jpg" ></td>',
              '<td style="width: 60%">',
              '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 9310 2680" preserveAspectRatio="xMidYMid meet">',
              '<g id="layer101" fill="#010101" stroke="none">',
              '<path d="M710 2334 c-19 -8 -52 -31 -72 -52 -31 -29 -38 -43 -38 -74 0 -51 32 -81 204 -193 309 -202 393 -259 460 -314 227 -183 278 -223 349 -270 88 -59 140 -83 248 -116 144 -44 172 -48 864 -135 176 -22 381 -49 455 -60 74 -10 241 -31 370 -45 233 -25 863 -82 1280 -116 74 -6 166 -15 205 -19 38 -5 153 -18 255 -29 102 -11 200 -23 217 -26 18 -3 34 -5 36 -3 2 2 12 -4 22 -12 17 -14 274 -89 320 -93 18 -2 20 3 17 48 -2 28 -14 96 -28 151 -23 96 -25 127 -34 545 -5 244 -10 513 -10 597 l0 152 -37 0 c-64 0 -109 -17 -123 -48 -9 -19 -15 -89 -17 -227 -2 -110 -8 -211 -13 -225 -26 -70 -70 -248 -70 -282 0 -41 21 -102 45 -128 18 -20 6 -68 -37 -152 -30 -55 -33 -58 -67 -57 -30 2 -32 3 -8 6 l27 4 1 212 c1 117 3 226 4 242 2 17 6 64 9 105 8 104 22 145 62 190 38 42 33 50 -33 50 -28 0 -43 -8 -68 -35 -52 -55 -65 -132 -80 -495 -10 -232 -15 -270 -36 -270 -70 0 -573 59 -939 110 -239 34 -484 68 -545 76 -145 19 -529 32 -1005 34 -212 1 -396 3 -410 5 -14 2 -61 4 -105 4 -55 1 -75 4 -65 11 12 7 -18 43 -150 177 -91 93 -205 210 -255 262 -109 114 -148 135 -212 116 -25 -8 -63 -25 -84 -40 -69 -45 -46 -74 203 -250 84 -60 189 -136 233 -170 44 -34 89 -67 100 -73 30 -17 3 -15 -112 8 -243 49 -335 103 -627 369 -72 66 -216 205 -319 309 -235 238 -288 269 -387 226z m1548 -931 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z"/></g>',
              '<g id="layer102" fill="#56a95a" stroke="none">',
              '<path d="M3006 2642 c-7 -12 12 -100 34 -149 53 -120 211 -287 418 -441 218 -163 403 -260 611 -320 57 -17 107 -36 111 -43 5 -6 11 -46 15 -88 7 -74 23 -111 50 -111 22 0 126 85 240 195 61 59 138 127 173 153 44 33 62 52 62 68 0 27 -32 52 -79 60 -44 9 -259 156 -351 241 -19 18 -43 33 -54 33 -17 0 -18 -6 -11 -95 l7 -95 -39 0 c-21 0 -87 11 -146 25 -338 76 -632 233 -944 504 -85 73 -89 76 -97 63z m251 -274 c352 -246 651 -369 976 -402 63 -7 61 -10 59 81 -1 18 3 35 9 38 14 9 112 -48 207 -122 67 -52 80 -67 73 -81 -8 -16 -137 -137 -226 -213 -22 -19 -51 -44 -65 -56 -39 -36 -37 -40 -53 111 -2 14 -28 28 -112 57 -348 124 -612 272 -794 445 -117 111 -193 204 -166 204 2 0 44 -28 92 -62z"/>',
              '<path d="M9263 3 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>',
              "</g></svg>",
              "</td>",
              "</tr>",
              "</table>",
            ].join("");
          }

          /**
           * aggiungo riga alla tabella per nome incisio e (colore)
           */
          if (this.getActiveConfiguration.nome != "") {
            name = [
              "<tr>",
              "<th>" + this.$t("message.Nome") + "</th>",
              "<td>",
              this.getActiveConfiguration.nome,
              " (",
              this.getActiveConfiguration.coloreNome,
              ")</td>",
              "</tr>",
            ].join("");
          } else {
            name = "";
          }

          let url1 = window.location.href;
          let idc1 = url1.split("/");
          if (parseInt(idc1[idc1.length - 1]) > 0) {
            idc1.pop();
            url_to_stamp = idc1.join("/") + "/";
          } else {
            url_to_stamp = window.location.href;
          }

          var template = [
            "<div>",
            '<div><h1>O-SIX Custom</h1><img class="logo" src="https://media.thema-optical.com/loghi/OSIX_ARTMADE.png"></div>',
            "<br>",
            "<div>",
            preview,
            "</div>",
            "<br>",
            "<table>",
            "<tr>",
            "<th>" + this.$t("message.Modello") + "</th>",
            "<td>",
            this.frontModel,
            "</td>",
            "</tr>",
            "<tr>",
            "<th>" + this.$t("message.Colore") + "</th>",
            "<td>",
            this.frontColor,
            "</td>",
            "</tr>",
            "<tr>",
            "<th>" + this.$t("message.Misura") + "</th>",
            "<td> ",
            this.frontSize,
            "</td>",
            "</tr>",
            "<tr>",
            "<th>" + this.$t("message.Finitura") + "</th>",
            "<td>",
            finituraF,
            "</td>",
            "</tr>",
            "<tr>",
            "<th>" + this.$t("message.Aste") + "</th>",
            "<td>",
            this.templesColor.ItemName,
            "</td>",
            "</tr>",
            "<tr>",
            "<th>" +
            this.$t("message.Metallo") +
            " " +
            this.$t("message.Aste") +
            "</th>",
            "<td>",
            metal,
            "</td>",
            "</tr>",
            "<tr>",
            "<th>" +
            this.$t("message.Finitura") +
            " " +
            this.$t("message.Aste") +
            "</th>",
            "<td>",
            finituraA,
            "</td>",
            "</tr>",
            name,
            "</table>",
            '<div style="margin: auto; max-width: 35%; max-height: 35%">',
            '<img src="' + this.qrcode + '" />',
            '<i style="position: relative; left: 35px">',
            url_to_stamp,
            "",
            this.id_for_url,
            "</i>",
            "</div>",
            "<style>",
            "body{font-family: arial;}",
            "img{width: 100%}",
            "h1{float: left}",
            "table{width: 100%; border-collapse: collapse; margin-bottom: 15px}",
            "td, th {border: 1px solid #dddddd;text-align: left;padding: 8px;}",
            "tr:nth-child(odd) {background: #dddddd;}",
            ".logo{width:20%; float: right;}",
            "</style>",
          ].join("");

          // Alt 1: Blob approach
          var blob = new Blob([template], { type: "text/html" });
          var blob_url = URL.createObjectURL(blob);
          var blob_iframe = document.querySelector("#print-frame");

          blob_iframe.src = blob_url;

          setTimeout(() => {
            resolve();
          }, 200);
        });
      })
      .then(() => {
        document.getElementById("print-frame").contentWindow.focus();
        document.getElementById("print-frame").contentWindow.print();

        this.$emit("closePrint");

        // this.doRender();
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  // getMessageCrypto() {
  //   let json = JSON.stringify(this.getActiveConfiguration);
  //   let crypto = window.btoa(json);
  //   return crypto;
  // },
  makeQRCode(id) {
    // let QRCode = require("qrcode");
    let url = window.location.href;
    let id1 = url.split("/");
    if (parseInt(id1[id1.length - 1]) > 0) {
      id1.pop();
      return QRCode.toDataURL(id1.join("/") + "/" + id);
    } else {
      return QRCode.toDataURL(window.location.href + id);
    }
  },
  getQRCode() {
    // let self = this;
    // let url = window.location.href;
    // let id = url.split("/");
    // id = id[id.length - 1];
    let id = this.$route.params.id;

    //nel caso in cui sia richiesta una configurazione via qr-code la carico
    //altrimenti viene caricato un modello casuale da Frontali
    if (parseInt(id) > 0) {
      // ajax_request.getCustomConfigurations(id).then(function(res) {
      //   self.restoreJsonConfiguration(res.data);
      // });
      this.getCustomConfigurations(id);
    } /*else{
          // filtro this.frontali usando il cod modello salvato in id e poi lancio this.setFrontal(id);

          let f = this.frontali.filter(fr => {
            return fr.U_THE_MODELLO == id
          })[0];
          this.setFrontal(f);
        }*/
  },
  setDracoLoader() {
    // THREE.DRACOLoader.setDecoderPath(`${process.env.VUE_APP_PUBLIC_PATH}/draco/`);
    // console.warn('link draco loader ', `${process.env.VUE_APP_PUBLIC_PATH}/draco/`);
    const path = `${process.env.VUE_APP_PUBLIC_PATH}draco/`;
    const loader = new DRACOLoader();
    loader.setDecoderPath(path);
    loader.setDecoderConfig({ type: "js" });
    loader.preload();
    return loader;
  },
  releaseDracoMemory() {
    // THREE.DRACOLoader.releaseDecoderModule();
  },
  loadFront() {
    this.startLoading = true;
    //this.updateTemplesGeometry = true;
    var self = this;
    // let model_data = _.find(this.models_size, (m) => {
    //   return m.model == self.frontModel;
    // });
    //let neutralMaterial = new THREE.MeshStandardMaterial();

    var dl = this.setDracoLoader();
    //this.loader.load(
    dl.load(
      //'/geometry/OSIX/'+this.frontModel+'.drc',
      `https://media.thema-optical.com/3d_models/${this.frontModel}.drc`,
      (object) => {
        object.computeBoundingBox();

        self.front = object;
        self.startLoading = false; //il dialog di caricamento viene chiuso da isLoading

        self.setBufferFrontUV();

        self.front.rotateX(self.rotationX);
        self.front.rotateY(self.rotationY);
        self.front.rotateZ(self.rotationZ);
        self.front.translate(self.offsetX, self.offsetY, self.offsetZ);
        self.front.clearGroups();
        self.front.addGroup(0, Infinity, 0);
        self.front.addGroup(0, Infinity, 1);
        self.front.addGroup(0, Infinity, 2);
        self.front = new THREE.Mesh(self.front, self.front_material);
        self.front.castShadow = true;
        self.front.renderOrder = 10;
        self.front.name = "frontale";
        const helper = new VertexNormalsHelper(self.front, 5, 0x00ff00, 1);

        self.setFrontalFinishing();
        //self.front = temp;
        //self.loadingGeometry += 15;
        if (self.frontColor) {
          self.setFrontalTexture();
          // self.scene.add(helper);
        }
        self.componentsLoaded += 1;
        self.isGeometryLoaded();
        self.loadTemples();
        dl = null;
      }
    );
  },
  setFrontalFinishing() {
    if (this.finitura == "S") {
      // this.front.material.metalness = this.plastic_lucid_settings.metalness;
      // this.front.material.roughness = this.plastic_lucid_settings.roughness;
      this.front.material.forEach((element) => {
        if (element.name == "mainMaterial") {
          element.roughness = this.plastic_lucid_settings.roughness;
          element.clearcoat = this.plastic_lucid_settings.coat;
          element.clearcoatRoughness = this.plastic_lucid_settings.coatRoughness;
        } else if (element.name == "shaderMaterial") {
          element.uniforms.thicknessDistortion.value = 0.8;
          element.uniforms.thicknessScale.value = 1.5;
          element.uniforms.thicknessAttenuation.value = 2;
        } else if (element.name == "matLens") {
          element.roughness = this.plastic_lucid_settings.roughness;
          element.clearcoat = this.plastic_lucid_settings.coat;
          element.clearcoatRoughness = this.plastic_lucid_settings.coatRoughness;
          element.envMapIntensity = this.plastic_lucid_settings.envMapIntensity;
        }
      });
    } else if (this.finitura == "M") {
      // this.front.material.metalness = this.plastic_matt_settings.metalness;
      // this.front.material.roughness = this.plastic_matt_settings.roughness;
      this.front.material.forEach((element) => {
        if (element.name == "mainMaterial") {
          element.roughness = this.plastic_matt_settings.roughness;
          element.clearcoat = this.plastic_matt_settings.coat;
          element.clearcoatRoughness = this.plastic_matt_settings.coatRoughness;
        } else if (element.name == "shaderMaterial") {
          element.uniforms.thicknessScale.value = 2;
          element.uniforms.thicknessDistortion.value = 0.5;
          element.uniforms.thicknessAttenuation.value = 3;
        } else if (element.name == "matLens") {
          element.roughness = this.plastic_matt_settings.roughness;
          element.clearcoat = this.plastic_matt_settings.coat;
          element.clearcoatRoughness = this.plastic_matt_settings.coatRoughness;
          element.envMapIntensity = this.plastic_matt_settings.envMapIntensity;
        }
      });
    }
    this.front.material[0].needsUpdate = true;
    // this.front.material[1].needsUpdate = true;
    // this.front.material[2].needsUpdate = true;
    this.front.needsUpdate = true;
    // this.doRender();
  },
  loadTemples() {
    // THREE.DRACOLoader.releaseDecoderModule();
    var self = this;
    let components = 2;
    let componentsLoaded = 0;
    // let model_data = _.find(this.models_size, (m) => {
    //   return m.model == self.frontModel;
    // });
    let resourceUrlSx =
      "https://media.thema-optical.com/3d_models/" + this.frontModel;
    let resourceUrlDx =
      "https://media.thema-optical.com/3d_models/" + this.frontModel;
    let dl1 = this.setDracoLoader();
    let dl2 = this.setDracoLoader();

    //verifico se è richiesto il refresh delle geometrie delle aste
    // che avviene o al cambio modello o al cambio materiale aste
    // vedi watch templesMaterial
    //if (this.updateTemplesGeometry) {
    if (this.templesColor && this.templesMaterial == "M") {
      resourceUrlSx += "_AMPSX.drc";
      resourceUrlDx += "_AMPDX.drc";
    } else {
      //default aste in plastica
      resourceUrlSx += "_ASX.drc";
      resourceUrlDx += "_ADX.drc";
    }
    //this.updateTemplesGeometry = false;
    //}

    //this.loader.load(
    dl1.load(
      resourceUrlDx,
      function (object) {
        //var temp = new THREE.Geometry();

        self.temple_dx = object;
        componentsLoaded += 1;
        if (componentsLoaded == components) {
          templesLoaded();
        }
        self.loadingGeometry += 10;
      } /*,
              function(xhr){
                  self.loadingGeometry += parseInt((xhr.loaded/xhr.total) * 10); //frontale = 20% totale
              }*/
    );
    //this.loader.load(
    dl2.load(
      resourceUrlSx,
      function (object) {
        self.temple_sx = object;
        componentsLoaded += 1;
        if (componentsLoaded == components) {
          templesLoaded();
        }

        self.loadingGeometry += 10;
      } /*,
              function(xhr){
                  self.loadingGeometry += parseInt((xhr.loaded/xhr.total) * 10); //frontale = 20% totale
              }*/
    );

    function templesLoaded() {
      // let material = new THREE.MeshStandardMaterial();

      self.setBufferTempleDxUV();
      self.temple_dx.rotateX(self.rotationX);
      self.temple_dx.rotateY(self.rotationY);
      self.temple_dx.rotateZ(self.rotationZ);
      self.temple_dx.translate(self.offsetX, self.offsetY, self.offsetZ);
      self.temple_dx.clearGroups();
      self.temple_dx.addGroup(0, Infinity, 0);
      self.temple_dx.addGroup(0, Infinity, 1);
      self.temple_dx.addGroup(0, Infinity, 2);
      self.temple_dx = new THREE.Mesh(self.temple_dx, self.temples_material);
      self.temple_dx.castShadow = true;
      self.temple_dx.name = "asta destra";
      const helper1 = new VertexNormalsHelper(self.temple_dx, 5, 0x00ff00, 1);

      self.setBufferTempleSxUv();
      self.temple_sx.rotateX(self.rotationX);
      self.temple_sx.rotateY(self.rotationY);
      self.temple_sx.rotateZ(self.rotationZ);
      self.temple_sx.translate(self.offsetX, self.offsetY, self.offsetZ);
      self.temple_sx.clearGroups();
      self.temple_sx.addGroup(0, Infinity, 0);
      self.temple_sx.addGroup(0, Infinity, 1);
      self.temple_sx.addGroup(0, Infinity, 2);
      self.temple_sx = new THREE.Mesh(self.temple_sx, self.temples_material);
      self.temple_sx.castShadow = true;
      self.temple_sx.name = "asta sinistra";
      const helper2 = new VertexNormalsHelper(self.temple_sx, 5, 0x00ff00, 1);

      if (self.templesColor) {
        self.setTemplesTexture();
      }
      // self.scene.add(helper1);
      // self.scene.add(helper2);
      self.setTemplesFinishing();
      self.front.add(self.temple_dx);
      self.front.add(self.temple_sx);
      //self.loadingGeometry += 35;
      self.componentsLoaded += 2;
      if (self.updateTemplesGeometry) {
        self.isGeometryLoaded(4);
      } else {
        self.isGeometryLoaded();
      }
      self.loadPins();
    }
  },
  loadPins() {
    var self = this;
    // let model_data = _.find(this.models_size, (m) => {
    //   return m.model == self.frontModel;
    // });
    let dl1 = this.setDracoLoader();
    let dl2 = this.setDracoLoader();
    let resourceUrlDx =
      "https://media.thema-optical.com/3d_models/" + this.frontModel;
    let resourceUrlSx =
      "https://media.thema-optical.com/3d_models/" + this.frontModel;
    let pinsComponents = 2;
    let pinsComponentsLoaded = 0;

    if (this.templesColor && this.templesMaterial == "M") {
      resourceUrlSx += "_AMSX.drc";
      resourceUrlDx += "_AMDX.drc";
    } else {
      resourceUrlSx += "_CSX.drc";
      resourceUrlDx += "_CDX.drc";
    }

    dl1.load(resourceUrlDx, function (object) {
      //definisco la mappatura UV
      self.pin_dx = object;

      pinsComponentsLoaded += 1;
      if (pinsComponentsLoaded == pinsComponents) {
        loadingPinsComplete();
      }
    });

    dl2.load(resourceUrlSx, function (object) {
      self.pin_sx = object;

      pinsComponentsLoaded += 1;
      if (pinsComponentsLoaded == pinsComponents) {
        loadingPinsComplete();
      }
    });

    function loadingPinsComplete() {
      let material;
      if (self.coloreMetalloAste) {
        if (self.$store.state.coloreMetalloAste == "PRO0000272") {
          //GUN
          material = Metal.metal_gun_material;
        } else if (self.coloreMetalloAste == "PRO0000280") {
          //GOLD
          material = Metal.metal_gold_material;
        }
      } else {
        material = Metal.metal_silver;
      }
      self.pin_dx = self.setBufferPlanarUv(self.pin_dx);
      self.pin_dx.rotateX(self.rotationX);
      self.pin_dx.rotateY(self.rotationY);
      self.pin_dx.rotateZ(self.rotationZ);
      self.pin_dx.translate(self.offsetX, self.offsetY, self.offsetZ);
      self.pin_dx = new THREE.Mesh(self.pin_dx, material);
      self.pin_dx.castShadow = true;
      // self.pin_dx.receiveShadow = true;
      self.pin_dx.name = "pin_destro";
      self.front.add(self.pin_dx);

      self.pin_sx = self.setBufferPlanarUv(self.pin_sx);
      self.pin_sx.rotateX(self.rotationX);
      self.pin_sx.rotateY(self.rotationY);
      self.pin_sx.rotateZ(self.rotationZ);
      self.pin_sx.translate(self.offsetX, self.offsetY, self.offsetZ);
      self.pin_sx = new THREE.Mesh(self.pin_sx, material);
      self.pin_sx.castShadow = true;
      // self.pin_sx.receiveShadow = true;
      self.pin_sx.name = "pin_sinistro";
      self.front.add(self.pin_sx);

      self.componentsLoaded += 2;
      if (self.updateTemplesGeometry) {
        self.isGeometryLoaded(4);
      } else {
        self.isGeometryLoaded();
      }
    }
  },
  setBufferFrontUV() {
    var self = this;
    this.front.computeBoundingBox();
    var max = this.front.boundingBox.max,
      min = this.front.boundingBox.min,
      offset = new THREE.Vector2(0 - min.x, 0 - min.z),
      range = new THREE.Vector2(max.x - min.x, max.z - min.z),
      faces = this.front.getAttribute("position").array,
      /*model_height = _.find(this.models_size, m => {
          return m.model == self.frontModel
      }).height,*/
      model_data = _.find(this.models_size, (m) => {
        return m.model == self.frontModel;
      }),
      relative_height = model_data.height / 80,
      to_center = (1 - relative_height) / 2;
    /**
     * relativeHeight => la texture viene considerata di altezza 1, per avere una
     * mappatura corretta per i multilayer devo considerarla come alta 80. quindi
     * divido l'altezza della tavoletta per 80 e moltiplico per l'altezza della texture,
     * (h/80)*1
     *
     * to_center => per ottenere un risultato ottimale devo centrare l'occhiale nella texture
     * quindi considero la differenza tra altezza totale e altezza relativa e trovo lo
     * scarto totale, dividendolo per due trovo l'offset per centrare l'occhiale
     *
     */

    var faceVertexUvs = [];

    for (var i = 2; i < faces.length; i += 3) {
      /*
        Ogni faccia è composta da 3 vertici, ogni vertice è definito da 3 coordinate x, y e z
        trattandosi di una buffer geometry i dati sono salvati solo nell'array position negli
        attributi della geometria in modo sequenziale, per lavorare su una faccia devo utilizzare
        i primi 9 elementi dell'array
       */

      //salvo i vertici della faccia che sto processando
      /*var v1 = this.front.vertices[faces[i].a],
          v2 = this.front.vertices[faces[i].b],
          v3 = this.front.vertices[faces[i].c];*/
      /*var v1 = new THREE.Vector3(faces[i-8], faces[i-7], faces[i-6]);
      var v2 = new THREE.Vector3(faces[i-5], faces[i-4], faces[i-3]);*/
      var v1 = new THREE.Vector3(faces[i - 2], faces[i - 1], faces[i]);

      //calcolo la coordinata y considerando l'offset associato al frontale
      var v1_y = ((v1.z + offset.y) / range.y) * relative_height + to_center;
      /*v2_y = (((v2.z + offset.y)/range.y) * relative_height) + to_center,
      v3_y = (((v3.z + offset.y)/range.y) * relative_height) + to_center;*/

      /*faceVertexUvs.push([
          new THREE.Vector2((v1.x + offset.x)/range.x , v1_y),
          //new THREE.Vector2((v1.x + offset.x)/range.x ,(v1.z + offset.y)/range.y),
          new THREE.Vector2((v2.x + offset.x)/range.x , v2_y),
          //new THREE.Vector2((v2.x + offset.x)/range.x ,(v2.z + offset.y)/range.y),
          new THREE.Vector2((v3.x + offset.x)/range.x , v3_y)
          //new THREE.Vector2((v3.x + offset.x)/range.x ,(v3.z + offset.y)/range.y)
      ]);*/
      faceVertexUvs.push((v1.x + offset.x) / range.x);
      faceVertexUvs.push(v1_y);
      /*faceVertexUvs.push((v2.x + offset.x)/range.x)
      faceVertexUvs.push(v2_y)
      faceVertexUvs.push((v3.x + offset.x)/range.x)
      faceVertexUvs.push(v3_y)*/
    }
    faceVertexUvs = new Float32Array(faceVertexUvs);
    var uvAttribute = new THREE.BufferAttribute(faceVertexUvs, 2);
    // this.front.addAttribute("uv", uvAttribute);
    this.front.setAttribute("uv", uvAttribute);
  },
  setBufferTempleDxUV() {
    // var self = this;

    if (typeof this.temple_dx.computeBoundingBox != "function") {
      return true;
    }

    this.temple_dx.computeBoundingBox();

    var max = this.temple_dx.boundingBox.max,
      min = this.temple_dx.boundingBox.min,
      offset = new THREE.Vector2(0 - min.z, 0 - min.y),
      range = new THREE.Vector2(max.z - min.z, max.y - min.y),
      faces = this.temple_dx.getAttribute("position").array;

    var faceVertexUvs = [];

    for (var i = 2; i < faces.length; i += 3) {
      const v1 = new THREE.Vector3(faces[i - 2], faces[i - 1], faces[i]);

      //calcolo la coordinata y considerando l'offset associato al frontale
      var v1_y = (v1.y + offset.y) / range.y;

      faceVertexUvs.push((v1.z + offset.x) / range.x);
      faceVertexUvs.push(v1_y);
    }

    faceVertexUvs = new Float32Array(faceVertexUvs);
    var uvAttribute = new THREE.BufferAttribute(faceVertexUvs, 2);
    this.temple_dx.setAttribute("uv", uvAttribute);
  },
  setBufferTempleSxUv() {
    if (typeof this.temple_sx.computeBoundingBox != "function") {
      return true;
    }

    this.temple_sx.computeBoundingBox();
    var max = this.temple_sx.boundingBox.max,
      min = this.temple_sx.boundingBox.min,
      offset = new THREE.Vector2(0 - min.z, 0 - min.y),
      range = new THREE.Vector2(max.z - min.z, max.y - min.y),
      faces = this.temple_sx.getAttribute("position").array;

    var faceVertexUvs = [];

    for (var i = 2; i < faces.length; i += 3) {
      //salvo i vertici della faccia che sto processando
      var v1 = new THREE.Vector3(faces[i - 2], faces[i - 1], faces[i]);

      //calcolo la coordinata y considerando l'offset associato al frontale
      var v1_y = (v1.y + offset.y) / range.y;

      faceVertexUvs.push((v1.z + offset.x) / range.x);
      faceVertexUvs.push(v1_y);
    }

    faceVertexUvs = new Float32Array(faceVertexUvs);
    var uvAttribute = new THREE.BufferAttribute(faceVertexUvs, 2);
    this.temple_sx.setAttribute("uv", uvAttribute);
  },
  setBufferPlanarUv: (g) => {
    if (typeof g.computeBoundingBox != "function") return true;
    g.computeBoundingBox();
    var max = g.boundingBox.max,
      min = g.boundingBox.min,
      offset = new THREE.Vector2(0 - min.x, 0 - min.z), //(altezza, profondità)
      range = new THREE.Vector2(max.x - min.x, max.z - min.z),
      faces = g.getAttribute("position").array;

    var faceVertexUvs = [];

    for (var i = 2; i < faces.length; i += 3) {
      var v1 = new THREE.Vector3(faces[i - 2], faces[i - 1], faces[i]);

      //calcolo la coordinata y considerando l'offset associato al frontale
      var v1_y = (v1.z + offset.y) / range.y;

      faceVertexUvs.push((v1.x + offset.x) / range.x);
      faceVertexUvs.push(v1_y);
    }

    faceVertexUvs = new Float32Array(faceVertexUvs);
    var uvAttribute = new THREE.BufferAttribute(faceVertexUvs, 2);

    g.setAttribute("uv", uvAttribute);

    return g;
  },
  setFrontalTexture() {
    var self = this;

    if (_.isEmpty(this.front)) {
      setTimeout(() => {
        self.setFrontalTexture();
      }, 500);
      return;
    }

    this.texture_loader.load(
      "https://media.thema-optical.com/colori_acetato/frontali/" +
      this.frontColor +
      ".jpg",
      (texture) => {
        // self.front.material.map = texture;
        // self.front.material.needsUpdate = true;
        self.front.material.forEach((m) => {
          if (m.name == "mainMaterial") {
            m.map = texture;
            // m.transparent = false;
            m.needsUpdate = true;
            m.dispose();
          } else if (m.name == "matLens") {
            m.dispose();
          } else if (m.name == "shaderMaterial") {
            m.uniforms.map.value = texture;
            m.uniforms.thicknessMap.value = texture;
          }
          texture.dispose();
        });
        self.render.render(self.scene, self.camera);
      }
    );
    this.texture_loader.load(
      "https://media.thema-optical.com/colori_acetato/frontali/alpha/" +
      this.frontColor +
      ".jpg",
      function (texture) {
        // self.front.material.alphaMap = texture;
        // self.front.material.transparent = true;
        // self.front.material.needsUpdate = true;
        self.front.material.forEach((m) => {
          m.dispose();
          if (m.name == "mainMaterial") {
            m.alphaMap = texture;
            m.transparent = true;
            m.needsUpdate = true;
          } else if (m.name == "matLens") {
            m.alphaMap = texture;
            // eslint-disable-next-line no-empty
          } else if (m.name == "shaderMaterial") {
          }
        });
        self.render.render(self.scene, self.camera);
      },
      undefined,
      function (err) {
        console.warn("Error loading alpha map for temples", err);
        self.front.material.forEach((m) => {
          m.dispose();
          if (m.name == "mainMaterial") {
            m.alphaMap = null;
            // m.transparent = true;
            m.needsUpdate = true;
          } else if (m.name == "matLens") {
            m.alphaMap = null;
            m.needsUpdate = true;
            // eslint-disable-next-line no-empty
          } else if (m.name == "shaderMaterial") {
            m.needsUpdate = true;
          }
        });
        self.render.render(self.scene, self.camera);
        //non esiste una mappa di trasparenza
        // self.front.material.alphaMap = null;
        // self.front.material.needsUpdate = true;
      }
    );
  },
  setTemplesTexture() {
    var self = this;
    /*if (this.updateTemplesGeometry) {
      this.loadTemples()
    }*/
    // self.temples_material.transparent = true;
    if (this.templesColor) {
      let urlCode = this.templesColor.U_THE_COLORE;
      if (this.templesColor.S_texture >= 0) {
        //this.texture_loader.load('http://configurator.themaoptical.com/'+this.templesColor.texture, function(texture){
        //this.texture_loader.load('http://configurator.local/textures/aste/PL0968.jpg', function(texture){
        this.texture_loader.load(
          "https://media.thema-optical.com/colori_acetato/aste/" +
          urlCode +
          ".jpg",
          function (texture) {
            // self.temples_material.map = texture;
            // self.temples_material.map.rotation = 0;
            // self.temples_material.needsUpdate = true;
            self.temples_material.forEach((m) => {
              m.dispose();
              if (m.name == "mainMaterial") {
                m.map = texture;
                // m.transparent = false;
                m.map.rotation = 0;
                m.needsUpdate = true;
              } else if (m.name == "matLens") {
                // eslint-disable-next-line no-empty
              } else if (m.name == "shaderMaterial") {
              }
            });
            texture.dispose();
            self.render.render(self.scene, self.camera);
          }
        );
        //this.texture_loader.load('http://configurator.themaoptical.com/textures/aste/alpha/'+this.templesColor.codice+'.jpg',
        this.texture_loader.load(
          "https://media.thema-optical.com/colori_acetato/aste/alpha/" +
          urlCode +
          ".jpg",
          function (texture) {
            // self.temples_material.alphaMap = texture;
            // //self.temples_material.transparent = true;
            self.temples_material.forEach((m) => {
              m.dispose();
              if (m.name == "mainMaterial") {
                m.alphaMap = texture;
                m.transparent = true;
                m.needsUpdate = true;
                // eslint-disable-next-line no-empty
              } else if (m.name == "matLens") {
                // eslint-disable-next-line no-empty
              } else if (m.name == "shaderMaterial") {
              }
            });
            texture.dispose();
            self.render.render(self.scene, self.camera);
          },
          undefined,
          function (err) {
            console.warn("Error loading alpha map for temples", err);
            //non esiste una mappa di trasparenza
            self.temples_material.forEach(m => {
              m.alphaMap = null;
              m.needsUpdate = true;
            })
          }
        );
      } else {
        self.temples_material.color = new THREE.Color(
          "rgb(" + this.templesColor.color + ")"
        );
      }
    }
    self.temples_material.needsUpdate = true;
  },
  setTemplesFinishing() {
    if (this.finituraAste == "S") {
      this.temples_material.forEach((m) => {
        m.needsUpdate = true;

        if (m.name == "mainMaterial") {
          m.roughness = this.plastic_lucid_settings.roughness;
          m.clearcoat = this.plastic_lucid_settings.coat;
          m.clearcoatRoughness = this.plastic_lucid_settings.coatRoughness;
        } else if (m.name == "matLens") {
          m.roughness = this.plastic_lucid_settings.roughness;
          m.clearcoat = this.plastic_lucid_settings.coat;
          m.clearcoatRoughness = this.plastic_lucid_settings.coatRoughness;
          m.side = THREE.DoubleSide;
        } else if (m.name == "shaderMaterial") {
          m.uniforms.thicknessPower.value = 2;
        }
      });
      // this.temple_dx.material.metalness = this.plastic_lucid_settings.metalness;
      // this.temple_sx.material.metalness = this.plastic_lucid_settings.metalness;
      // this.temple_dx.material.roughness = this.plastic_lucid_settings.roughness;
      // this.temple_sx.material.roughness = this.plastic_lucid_settings.roughness;
    } else if (this.finituraAste == "M") {
      // this.temple_dx.material.metalness = this.plastic_matt_settings.metalness;
      // this.temple_sx.material.metalness = this.plastic_matt_settings.metalness;
      // this.temple_dx.material.roughness = this.plastic_matt_settings.roughness;
      // this.temple_sx.material.roughness = this.plastic_matt_settings.roughness;
      this.temples_material.forEach((m) => {
        m.needsUpdate = true;
        if (m.name == "mainMaterial") {
          m.roughness = this.plastic_matt_settings.roughness;
          m.clearcoat = this.plastic_matt_settings.coat;
          m.clearcoatRoughness = this.plastic_matt_settings.coatRoughness;
        } else if (m.name == "matLens") {
          m.roughness = this.plastic_matt_settings.roughness;
          m.clearcoat = this.plastic_matt_settings.coat;
          m.clearcoatRoughness = this.plastic_matt_settings.coatRoughness;
          m.side = THREE.FrontSide;
        } else if (m.name == "shaderMaterial") {
          // m.uniforms.thicknessPower.value = 0;
          // m.uniforms.thicknessScale.value = 2;
          // m.uniforms.thicknessDistortion.value = 0.5;
          // m.uniforms.thicknessAttenuation.value = 3;
        }
      });
    }
    this.temple_dx.material.needsUpdate = true;
    this.temple_dx.needsUpdate = true;
    // this.doRender();
    this.temple_sx.material.needsUpdate = true;
    this.temple_sx.needsUpdate = true;
    // this.doRender();
  },
  onWindowResize() {
    // eslint-disable-next-line no-undef
    camera.aspect = this.width / this.height;
    // eslint-disable-next-line no-undef
    camera.updateProjectionMatrix();
    //this.render.setSize( window.innerWidth, window.innerHeight );
    //renderer.setSize( this.width, this.height );
  },
  isGeometryLoaded(components) {
    components = components ? components : this.componentsCount;
    this.loadingGeometry = parseInt((this.componentsLoaded / components) * 100);
    if (components == this.componentsLoaded) {
      this.stopLoadingGeometry();
      this.componentsLoaded = 0;
      this.releaseDracoMemory();
      this.scene.add(this.front);
      this.loadingGeometry = 0;
      this.updateTemplesGeometry = false;
    }

    // this.doRender();
  },
};
