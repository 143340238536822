<template>
  <div id="montatura">
    <ul class="body-title">
      <li class="menu-element active" @click="$emit('close')">
        <span>{{ $t('message.Montatura') | upperCase}}</span>
        <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <div class="body-elements">
      <el-row id="filter-row">
        <el-col :span="24" class="sizes-column">
          <span class="label">{{ $t('message.Misura') | upperCase}}</span>
          <el-radio-group v-model="size" class="sizes">
            <el-radio
              v-for="s in activeSizes"
              :key="s.id"
              :label="s | sizeLabel"
              name="size"
            ></el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="24">
          <el-row type="flex">
            <!-- <el-col :span="24">
            </el-col>-->
            <span class="label">Asian Fitting</span>
            <el-switch
              v-model="asianFitting"
              active-text="YES"
              inactive-text="NO"
              active-color="#1D1C1C"
              inactive-color="#A3A3A3"
              name="Asian Fitting"
            ></el-switch>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col class="model-filter-column" :span="20">
              <span class="label">{{ $t('message.Cerca') | upperCase}}</span>
              <el-input
                v-model="filter"
                size="small"
                clearable
                :placeholder="$t('message.Cerca')+` (${items.length} models)`"
              ></el-input>
            </el-col>
            <el-col class="icon-column" :span="4">
              <Search />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row id="models-preview-row">
        <el-col
          class="model-preview-column"
          :class="{ active: item.ItemCode == frontale.ItemCode }"
          v-for="item in filteredItems"
          :key="item.ItemCode"
          :span="12"
        >
          <div v-if="item.ItemCode == frontale.ItemCode" class="active-flag">
            <img src="../../assets/model-active.png" alt="active" />
          </div>
          <div class="model-preview-image" @click="setFrontal(item)">
            <img :src="item.U_THE_MODELLO | modelImage" alt />
          </div>
          <div class="model-preview-name">
            <span>{{ item.U_THE_MODELLO }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body-actions">
      <body-actions @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations } from "vuex";
  import jslinq from "jslinq";

  import BodyActions from "./BodyActions.vue";
  import Search from "vue-material-design-icons/Magnify.vue";

  export default {
    components: {
      BodyActions,
      Search,
    },
    filters: {
      sizeLabel(s) {
        return (
          s.size_eye.replace(".00", "") + "/" + s.size_bridge.replace(".00", "")
        );
      },
    },
    data() {
      return {
        filter: "",
        // asianFitting: false,
        // size: [],
      };
    },
    async mounted() {
      if (this.items.length == 0) await this.getItems(false);

      if (this.itemsSizes.length == 0) await this.getAllModelsSizes();
    },
    methods: {
      ...mapActions("frontali", ["getItems", "getAllModelsSizes"]),
      ...mapMutations(["setSize", "setFrontal", "setAsianFitting"]),
    },
    computed: {
      ...mapState("frontali", ["items", "itemsSizes"]),
      ...mapState(["frontale", "calibro", "naso"]),

      asianFitting: {
        get() {
          return this.$store.state.asianFitting;
        },
        set(v) {
          this.setAsianFitting(v);
        },
      },
      filteredItems() {
        let filter = this.filter;
        if (filter && filter != "") {
          let i = jslinq(this.items);
          return i
            .where((e) => {
              return e.U_THE_MODELLO.indexOf(filter) >= 0;
            })
            .toList();
        }

        return this.items;
      },
      activeSizes() {
        if (this.frontale != "") {
          let isz = jslinq(this.itemsSizes);

          return isz
            .where((size) => {
              return size.ItemCode == this.frontale.ItemCode;
            })
            .toList();
        }

        return [];
      },
      size: {
        get() {
          const sizes = jslinq(this.activeSizes);

          const sizeSelected = sizes.firstOrDefault((s) => {
            return (
              s.size_eye.replace(".00", "") == this.calibro &&
              s.size_bridge.replace(".00", "") == this.naso
            );
          });
          return sizeSelected
            ? this.$options.filters.sizeLabel(sizeSelected)
            : "";
        },
        set(s) {
          const sArray = s.split("/");
          this.$store.state.calibro = sArray[0];
          this.$store.state.naso = sArray[1].split(" - ")[0];
        },
      },
      calibroNaso() {
        return `${this.calibro}-${this.naso}`;
      },
    },
    watch: {
      size(newVal) {
        //cambio calibro/naso in base ai valori scelti
        let s = jslinq(this.activeSizes),
          cn = newVal.split("/"),
          result = s.firstOrDefault((size) => {
            return (
              size.size_eye.replace(".00", "") == cn[0] &&
              size.size_bridge.replace(".00", "") == cn[1].split("-")[0].trim()
            );
          });

        if (result !== null) {
          this.setSize(result);
        } else {
          this.$store.state.calibro = "";
          this.$store.state.naso = "";
        }
      },
    },
    calibroNaso(newVal) {
      const cn = newVal.split("-");
      const s = jslinq(this.activeSizes);

      const result = s.firstOrDefault((size) => {
        return (
          size.size_eye.replace(".00", "") == cn[0] &&
          size.size_bridge.replace(".00", "") == cn[1].split("-")[0].trim()
        );
      });

      // this.size = this.sizeLabel(result);
      this.size = this.$options.filters.sizeLabel(result);
    },
  };
</script>

<style lang="less">
  #models-preview-row {
    height: ~"calc(100% - 30px*4 - 10px*2)"; // 100% - 30 * (numero righe + 30 di padding) + margini * numero righe
  }
  .icon-column {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .model-preview-image {
    background-color: #e8e8e8;
    padding: 15px 8px;
    cursor: pointer;
    img {
      max-width: 90%;
      margin-left: 5%;
      margin: auto;
    }
  }
  #filter-row {
    .el-col {
      height: 30px !important;
    }
    .sizes-column {
      // height: 60px !important;
      .sizes {
        display: flex;
        flex-direction: row;
        text-align: left;
        label {
          padding: 2px 0px;
        }
      }
    }
  }

  @media @mobile {
    .model-preview-image {
      img {
        max-width: 80%;
      }
    }
  }
</style>